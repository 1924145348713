import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Typography, Box, CircularProgress, Link } from "@mui/material";
import Grid from "@mui/material/Grid2";
import apiService from "../../services/api.js";
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";

function TempleStaff() {
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);

  // Use the category ID for "Temple Staff" fetched from API (assumed category ID 1 for now).
  const categoryId = 1;

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch teams directly for the specified categoryId
        const teamsResponse = await apiService.getTeams(categoryId);

        console.log("Teams Response:", teamsResponse.data.data);

        setTeams(teamsResponse.data.data || []);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [categoryId]);

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      {teams.length > 0 ? (
        <Grid container spacing={4}>
          {teams.map((teamMember) => (
            <Grid item size={{ xs: 12 }} key={teamMember.id}>
              <Card
                className="staff-list"
                sx={{
                  display: "flex", // Use flexbox layout for the card
                  flexDirection: "column", // Align children vertically
                  height: "100%", // Ensure the card takes the full available height
                }}
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <Box component="div" className="staff-top">
                    <Box
                      component="figure"
                      className="staff-pic"
                      sx={{ m: 0, flexShrink: 0 }}
                    >
                      <CardMedia
                        component="img"
                        height="140"
                        image={teamMember.image || "placeholder.jpg"}
                        alt={teamMember.image_alt_text}
                        className="object-center-top"
                      />
                    </Box>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="h6"
                      sx={{ color: "primary.main" }}
                    >
                      {teamMember.name}
                    </Typography>

                    <Link
                      href={`tel:${teamMember.github_url}`}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <PhoneIcon /> {teamMember.github_url || "N/A"}
                    </Link>
                    <Link
                      href={`mailto:${teamMember.other}`}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <MailIcon /> {teamMember.other || "N/A"}
                    </Link>
                  </Box>

                  <Typography
                    gutterBottom
                    sx={{ mb: 2 }}
                    component="div"
                    className=" staff-content"
                    dangerouslySetInnerHTML={{
                      __html:
                        teamMember.description || "No description available.",
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography variant="h6" textAlign="center" sx={{ mt: 4 }}>
          No team members found.
        </Typography>
      )}
    </Box>
  );
}

export default TempleStaff;
