import React from "react";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import EventsList from "./EventsSlider";
import EventCalender from "./EventCalender";
import { Container } from "@mui/system";

function UpcomingEvents() {
  return (
    <Box component="section" className="upcoming-events">
      <Container maxWidth="xl">
      <Box>
      <Typography
        component="h2"
        variant="h1"
        className="section-title text-primary"
      >
        Upcoming Events
      </Typography>
      <Grid
        container
        spacing={2}
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid item size={{ md: 9, xs:12 }}>
      
          <EventsList />
        </Grid>

        <Grid item size={{ md: 3, xs:12 }}>
          <EventCalender/>
        </Grid>
      </Grid>
      </Box>
      </Container>
    </Box>
  );
}

export default UpcomingEvents;
