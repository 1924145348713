import * as React from "react";
import {
  Box,
  Card,
  Container,
  Typography,
  CardContent,
  CardMedia,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import Balvihar from "../../images/balvihar.jpg";
import Deepam from "../../images/deepam.jpg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useAppSettings } from "../../contexts/AppSettingsContext"; 

function BalviharAndDeepam() {
  const { balviharLink, deepamLink } = useAppSettings() || {};

  const cardData = [
    {
      title: "Balvihar",
      image: Balvihar,
      alt: "Balvihar image",
      link: balviharLink,
      target: true,
    },
    {
      title: "Deepam",
      image: Deepam,
      alt: "Deepam image",
      link: deepamLink,
      target: false,
    },
  ];

  return (
    <Box component="section" className="balvihar_deepam">
      <Container maxWidth="xl">
        <Grid
          container
          spacing={2}
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {cardData.map((card, index) => (
            <Grid item size={{ md: 6 }} key={index} className="block">
              <Card
                component="a" 
                href={card.link}
                target={card.target ? "_blank" : undefined} // Conditionally add target attribute
                rel={card.target ? "noopener noreferrer" : undefined} // Add rel for security when target="_blank"
                sx={{
                  display: "flex",
                  boxShadow: 3,
                  textDecoration: "none", // Removes underline from the card
                  transition: "all 0.3s ease-in-out", // Smooth hover transition
                  "&:hover": {
                    backgroundColor: "primary.main", // Primary color on hover
                    color: "white", // White text on hover
                  },
                }}
                className="card"
              >
                <CardMedia
                  component="img"
                  sx={{
                    width: 300,
                  }}
                  image={card.image}
                  alt={card.alt}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <CardContent sx={{ flex: "1 0 auto" }}>
                    <Typography
                      component="h4"
                      variant="h4"
                      sx={{
                        fontWeight: 700,
                        color: "inherit", // Makes text color adaptable
                        transition: "color 0.3s ease-in-out", // Smooth text color change
                      }}
                      className="text-primary font-700"
                    >
                      {card.title}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      component="div"
                      sx={{
                        color: "inherit", // Adapts to text color on hover
                        transition: "color 0.3s ease-in-out",
                      }}
                      className="link"
                    >
                      Read More <ArrowForwardIcon />
                    </Typography>
                  </CardContent>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default BalviharAndDeepam;
