import React from "react";
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  Link,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import F9Logo from "../../images/f9tech.svg";
import { useAppSettings } from "../../contexts/AppSettingsContext";
import "../footer/footer.css";
import SocialLinks from "../../components/contact-and-social-details/SocialLinks";
import ContactDetails from "../../components/contact-and-social-details/ContactDetails";
function Footer() {
  const {
    footerMenu,
    templeTimings,
    copyrightText,
  } = useAppSettings() || {};
  const currentYear = new Date().getFullYear();
  return (
    <Box component="section" className="footer " sx={{ p: 0 }}>
      <Box
        className="footer-inner bg-secondary theme-radius text-white"
        sx={{ py: 5 }}
      >
        <Container maxWidth="xl">
          <Grid
            container
            spacing={2}
            sx={{
              justifyContent: "space-between",
            }}
          >
            <Grid item size={{ md: 4 }} sx={{ pr: 3 }} className="full-block">
              <Typography component="h5" className="footer-title">
                Quick Links
              </Typography>
              <List className="footer-list">
                {footerMenu?.map((menu) => (
                  <ListItem key={menu.id}>
                    <Link
                      href={menu.custom_url || `/${menu.slug}`}
                      className="text-white"
                    >
                      <ArrowRightIcon /> {menu.title}
                    </Link>
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item size={{ md: 4 }} sx={{ pr: 3 }} className="full-block">
              <Typography component="h5" className="footer-title">
                Contact Us
              </Typography>
              <ContactDetails/>
             <SocialLinks />
            </Grid>
            <Grid item size={{ md: 4 }} sx={{ pr: 3 }} className="full-block">
              <Typography component="h5" className="footer-title">
                Temple Timings
              </Typography>
              <Box
                component="div"
                className="footer-timings"
                dangerouslySetInnerHTML={{ __html: templeTimings }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box className="bg-primary copyrights text-white" sx={{ py: 2 }}>
        <Container maxWidth="xl">
          <Box className="copyinner">
            <Typography>
              ©{currentYear} {copyrightText}
            </Typography>
            <Link href="https://f9tech.com/" target="_blank">
              Designed by:
              <Box component="img" src={F9Logo} />
            </Link>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
export default Footer;
