import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Box, Typography, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import apiService from "../../services/api";

function HeroSlider() {
  const [sliderData, setSliderData] = useState([]);

  useEffect(() => {

    const fetchSliderData = async () => {
      try {
        const response = await apiService.getSliders(); 
        if (response.data && response.data.success) {
          setSliderData(response.data.data); 
        }
      } catch (error) {
        console.error("Error fetching slider data:", error);
      }
    };

    fetchSliderData();
  }, []);

  const settings = {
    dots: false,
    arrows: false,
    infinite: sliderData.length > 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
  };

  return (
    <Slider {...settings}>
      {sliderData.map((item, index) => (
        <Box key={index}>
          <Box sx={{ flexGrow: 1, padding: 2 }}>
            <Grid
              container
              spacing={2}
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {/* Image Section */}
              <Grid item size={{ xs: 12, md: 8 }} order={{ xs: 1, md: 2 }}>
                <Box component="figure" className="banner-pic">
                  <Box
                    component="img"
                    src={item.image}
                    className="w-100 theme-radius"
                    alt={item.image_alt_text || "Slider Image"}
                  />
                </Box>
              </Grid>

              {/* Content Section */}
              <Grid item size={{ xs: 12, md: 4 }} order={{ xs: 2, md: 1 }}>
                <Box className="text-white text-inner">
                  <Typography variant="h1" gutterBottom>
                    <span dangerouslySetInnerHTML={{ __html: item.title }} />
                  </Typography>
                  <Typography className="lead" gutterBottom>
                    {item.tagline}
                  </Typography>
                  <Button
                    component="a"
                    href={item.button_url}
                    variant="contained"
                    color="white"
                    className="text-primary"
                    sx={{ mt: 3 }}
                  >
                    {item.button_name}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      ))}
    </Slider>
  );
}

export default HeroSlider;
