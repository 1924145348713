import React, { useEffect, useState } from "react";
import apiService from "../../services/api.js";
import { Typography, Box, List, Link, ListItem } from "@mui/material";

function Management() {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTeamCategories = async () => {
      try {
        const response = await apiService.getTeamCategories(); // Fetch all team categories
        setCategories(response.data.data || []);
      } catch (error) {
        console.error("Error fetching team categories:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTeamCategories();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Box className="team-list">
      {categories.map((category) => (
        <Box key={category.id}>
          <Typography className="text-primary" variant="h3">
            {category.display_name || category.name}
          </Typography>
          <List>
            {category.team && category.team.length > 0 ? (
              category.team.map((member) => (
                <ListItem className="team-list" key={member.slug}>
                  {member.name && <Typography component="h5">{member.name}</Typography>}
                  {member.designation && (
                   <Typography component="p">
                      ({member.designation})
                    </Typography>
                  )}
                </ListItem>
              ))
            ) : (
              <li>No team members found.</li>
            )}
          </List>
        </Box>
      ))}
    </Box>
  );
}

export default Management;
