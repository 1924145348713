import * as React from "react";
import TextField from "@mui/material/TextField";
import InnerLayout from "../../layouts/InnerLayout";
import InnerBanner from "../../images/inner-banner.jpg";
import Grid from "@mui/material/Grid2";
import { Box, Typography, Container } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import CallforAction from "../../components/call_for_action/CallForActtion.js"

function TempleToursRequest() {
  return (
    <InnerLayout>



<Box component="section" className="inner-banner" sx={{ p: 0 }}>
        <Box component="img" src={InnerBanner} />

        <Box className="inner-text">
          <Container maxWidth="xl">
            <Grid
              container
              spacing={2}
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid item size={{ md: 6 }}>
                <Typography
                  component="h1"
                  variant="h1"
                  className="section-title text-white"
                >
                 Temple Tour Request
                </Typography>
              </Grid>
              <Grid item size={{ md: 6 }}>
                <CallforAction />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>


     

      <Box component="section">
        <Container maxWidth="xl">
          <Box>
            <Typography component="p">
              <Typography component="span" className="text-primary font-700">
                {" "}
                Note:{" "}
              </Typography>
              <br />
              1. No Temple tours will be conducted on{" "}
              <Typography component="span" className="text-primary">
                Saturdays & Sundays{" "}
              </Typography>
              until further notice.Sorry for any inconvenience <br />
              2. Tours are conducted for{" "}
              <Typography component="span" className="text-primary">
                groups of 10 or more
              </Typography>
            </Typography>
          </Box>
          <Typography
            component="h5"
            variant="h5"
            sx={{ mt: 2 }}
            className="font-700 text-primary"
          >
            Fill The From
          </Typography>
          <Box
            component="form"
            sx={{ "& .MuiTextField-root": { mt: 2 } }}
            noValidate
            autoComplete="off"
            className="w-100"
          >
            <Grid container spacing={2}>
              <Grid item size={{ md: 6 }}>
                <TextField
                  required
                  className="w-100"
                  id="outlined-required"
                  label="Name of group/individual "
                  placeholder="Name of group/individual *"
                />
              </Grid>
              <Grid item size={{ md: 6 }}>
                &nbsp;
              </Grid>
              <Grid item size={{ md: 6 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs} className="w-100">
                  <DemoContainer components={["DatePicker"]} className="w-100">
                    <DatePicker label="Basic date picker" className="w-100" />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>


              <Grid item size={{ md: 6 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}   className="w-100">
      <DemoContainer components={['TimePicker']}   className="w-100">
        <TimePicker label="Basic time picker"   className="w-100" />
      </DemoContainer>
    </LocalizationProvider>
    </Grid>




            </Grid>
          </Box>
        </Container>
      </Box>
    </InnerLayout>
  );
}

export default TempleToursRequest;
