import React, { useEffect, useState } from "react";
import {
  Box,
  CssBaseline,
  Toolbar,
  IconButton,
  Drawer,
  Container,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import NavigationMenu from "./NavigationMenu";
import "./header.css";
import { useAppSettings } from "../../contexts/AppSettingsContext";
import apiService from "../../services/api.js";
import StaticLogo from "../../images/logo.png";


const drawerWidth = 240;
export default function Header({ window, theme, toggleTheme }) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [navItems, setNavItems] = useState([]);
  const { logo } = useAppSettings() || {};
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const handleDrawerClose = () => {
    setMobileOpen(false); // Close the drawer when a link is clicked
  };
  const container =
    window !== undefined ? () => window().document.body : undefined;
  useEffect(() => {
    const fetchNavItems = async () => {
      try {
        const response = await apiService.getCms();
        if (response.data.success) {
          const formattedNavItems = response.data.data.map((item) => ({
            name: item.title,
            path: `/${item.slug}`,
            custom_url: item.custom_url,
            type: item.type,
            subMenu: item.subMenu || [],
          }));
          setNavItems(formattedNavItems);
        }
      } catch (error) {
        console.error("Error fetching navigation items:", error);
      }
    };
    fetchNavItems();
  }, []);
  return (
    <Box>
      <CssBaseline />
      <Box component="header" position="static">
        <Link className="logo" to="/">
          <Box component="img" src={logo || StaticLogo} alt="Logo" />
        </Link>
        <Container maxWidth="xl" className="header-container">
          <Toolbar className="header-inner">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              <NavigationMenu navItems={navItems} isMobile={false} />
            </Box>
          </Toolbar>
        </Container>
      </Box>
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
      >
        <Box onClick={handleDrawerClose}>
          <NavigationMenu
            navItems={navItems}
            isMobile={true}
            onClose={handleDrawerClose}
          />
        </Box>
      </Drawer>
    </Box>
  );
}
