import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, 
});

const apiService = {
  getCms: () => api.get("/cms"),
  getCmsBySlug: (slug) => api.get(`/cms/${slug}`),
  getSliders: () => api.get("/sliders"),
  getPhotoGalleries: () => api.get("/photo-galleries"),
  getApplicationSettings: () => api.get("/applicationSettings"),
  getTeamCategories: () => api.get("/team-categories"),
  getTeams: (categoryId) => api.get(`/teams/${categoryId}`),
  getDeepamData: () => api.get("/clienteles/deepam"), 
};

export default apiService;
