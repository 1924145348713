import React from "react";
import PageLayout from "../layouts/PageLayout.js";
import ImageGallery from "../components/image_gallery/ImageGallery.js";
import JoinOurCommunication from "../components/join_our_communication/JoinOurCommunication.js";
import UpcomingEvents from "../components/upcoming_events/UpcomingEvents.js";
import PageBanner from "../components/page_banner/PageBanner.js";
import BalviharAndDeepam from "../components/balvihar_deepam/BalviharAndDeepam.js";

function Home() {
  return (
    <PageLayout>
      <PageBanner />
      <UpcomingEvents />
      <JoinOurCommunication />
      <BalviharAndDeepam />
      <ImageGallery />
    </PageLayout>
  );
}

export default Home;
