import React from "react";
import InnerLayout from "../../layouts/InnerLayout";
import InnerBanner from "../../images/inner-banner.jpg";
import Grid from "@mui/material/Grid2";
import { Box, Typography, Container } from "@mui/material";
import TypeOfEvent from "./TypeOfEvent";
import CallforAction from "../../components/call_for_action/CallForActtion.js";

function SocialHallBooking() {
  return (
    <InnerLayout>
      <Box component="section" className="inner-banner" sx={{ p: 0 }}>
        <Box component="img" src={InnerBanner} />

        <Box className="inner-text">
          <Container maxWidth="xl">
            <Grid
              container
              spacing={2}
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid item size={{ md: 6 }}>
                <Typography
                  component="h1"
                  variant="h1"
                  className="section-title text-white"
                >
                  Social Hall Booking
                </Typography>
              </Grid>
              <Grid item size={{ md: 6 }}>
                <CallforAction />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>

      <Box component="section">
        <Container maxWidth="xl">
          <TypeOfEvent />
        </Container>
      </Box>
    </InnerLayout>
  );
}

export default SocialHallBooking;
