import React from "react";
import { eventData } from "../../static-data/event-data.js";
import Grid from "@mui/material/Grid2";
import EventCard from "../../components/EventCard.js";
import InnerLayout from "../../layouts/InnerLayout.js";
import { Container } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InnerBanner from "../../images/inner-banner.jpg";
import CallforAction from "../../components/call_for_action/CallForActtion.js";

const parseDate = (dateString) => {
  const cleanedDate = dateString
    .replace(
      /(Monday|Tuesday|Wednesday|Thursday|Friday|Saturday|Sunday),?/gi,
      ""
    )
    .replace(/(\d+)(th|rd|nd|st)/, "$1")
    .replace(/,/g, "");
  const currentYear = new Date().getFullYear();
  const eventDateWithYear = cleanedDate.includes(currentYear)
    ? cleanedDate
    : cleanedDate + ` ${currentYear}`;
  return new Date(eventDateWithYear);
};

const filterEventsByCategory = (category) => {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  const tomorrow = new Date(currentDate);
  tomorrow.setDate(currentDate.getDate() + 1);

  const today = new Date(currentDate);

  const yesterday = new Date(currentDate);
  yesterday.setDate(currentDate.getDate() - 1);

  return eventData.filter((event) => {
    const eventDate = parseDate(event.date);

    if (category === "upcoming") {
      return eventDate >= tomorrow;
    } else if (category === "ongoing") {
      return eventDate >= today && eventDate < tomorrow;
    } else if (category === "past") {
      return eventDate < today;
    }
    return false;
  });
};

function Events() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const allEvents = eventData; // For Tab 1, show all events
  const upcomingEvents = filterEventsByCategory("upcoming");
  const ongoingEvents = filterEventsByCategory("ongoing");
  const pastEvents = filterEventsByCategory("past");

  return (
    <InnerLayout>
      <Box component="section" className="inner-banner" sx={{ p: 0 }}>
        <Box component="img" src={InnerBanner} />

        <Box className="inner-text">
          <Container maxWidth="xl">
            <Grid
              container
              spacing={2}
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid item size={{ md: 6 }}>
                <Typography
                  component="h1"
                  variant="h1"
                  className="section-title text-white"
                >
                  Events
                </Typography>
              </Grid>
              <Grid item size={{ md: 6 }}>
                <CallforAction />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>

      <Container maxWidth="xl">
        <Box sx={{ flexGrow: 1, display: "flex", height: "auto" }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Event Categories"
            sx={{ borderRight: 1, borderColor: "divider", minWidth: 200 }}
          >
            <Tab label="All Events" />
            <Tab label="Upcoming Events" />
            <Tab label="Ongoing Events" />
            <Tab label="Past Events" />
          </Tabs>

          <Box sx={{ flexGrow: 1, p: 3 }}>
            {/* Tab 1: All Events */}
            {value === 0 && (
              <Grid container spacing={2}>
                {allEvents.length > 0 ? (
                  allEvents.map((event, index) => (
                    <Grid item size={{ md: 4 }} key={index}>
                      <EventCard event={event} />
                    </Grid>
                  ))
                ) : (
                  <Typography>No events available</Typography>
                )}
              </Grid>
            )}

            {/* Tab 2: Upcoming Events */}
            {value === 1 && (
              <Grid container spacing={2}>
                {upcomingEvents.length > 0 ? (
                  upcomingEvents.map((event, index) => (
                    <Grid item size={{ md: 4 }} key={index}>
                      <EventCard event={event} />
                    </Grid>
                  ))
                ) : (
                  <Typography>No upcoming events</Typography>
                )}
              </Grid>
            )}

            {/* Tab 3: Ongoing Events */}
            {value === 2 && (
              <Grid container spacing={2}>
                {ongoingEvents.length > 0 ? (
                  ongoingEvents.map((event, index) => (
                    <Grid item size={{ md: 4 }} key={index}>
                      <EventCard event={event} />
                    </Grid>
                  ))
                ) : (
                  <Typography>No ongoing events</Typography>
                )}
              </Grid>
            )}

            {/* Tab 4: Past Events */}
            {value === 3 && (
              <Grid container spacing={2}>
                {pastEvents.length > 0 ? (
                  pastEvents.map((event, index) => (
                    <Grid item size={{ md: 4 }} key={index}>
                      <EventCard event={event} />
                    </Grid>
                  ))
                ) : (
                  <Typography>No past events</Typography>
                )}
              </Grid>
            )}
          </Box>
        </Box>
      </Container>
    </InnerLayout>
  );
}

export default Events;
