import React from "react";
import PropTypes from "prop-types"; // For prop validation
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Link } from "react-router-dom";
function EventCard({ event }) {
  return (
    <Box sx={{ px: 1 }} className="event-lists">
      <Card
        className="theme-radius event-card"
        sx={{ position: "relative", overflow: "hidden" }}
      >
        <CardMedia
          className="theme-radius event-img"
          component="img"
          alt={event.title}
          height="200"
          image={event.image}
        />
        <CardContent sx={{ pb: 4 }}>
          <Typography
            gutterBottom
            variant="h6"
            component="h3"
            dangerouslySetInnerHTML={{ __html: event.title }}
            sx={{ mb: 2 }}
          />
          <Typography
            variant="body2"
            sx={{
              color: "text.secondary",
              display: "flex",
              alignItems: "center",
              mb: 1,
            }}
          >
            <DateRangeIcon fontSize="small" sx={{ mr: 0.5 }} /> {event.date}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: "text.secondary",
              display: "flex",
              alignItems: "center",
            }}
          >
            <AccessTimeIcon fontSize="small" sx={{ mr: 0.5 }} /> {event.time}
          </Typography>
        </CardContent>
        <Link className="link" to={`/events/${event.slug}`}>
          <ArrowRightAltIcon />
        </Link>
      </Card>
    </Box>
  );
}
// PropTypes for validating props
EventCard.propTypes = {
  event: PropTypes.shape({
    title: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
};
export default EventCard;
