import React from "react";
import { Box, Link, List, ListItem } from "@mui/material";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import { useAppSettings } from "../../contexts/AppSettingsContext"; 

function ContactDetails() {
  const { address, primaryPhoneNumber, primaryEmail } = useAppSettings() || {};

  
  const contactDetails = [
    { icon: <FmdGoodIcon />, text: address, link: null },
    { icon: <LocalPhoneIcon />, text: primaryPhoneNumber, link: `tel:${primaryPhoneNumber}` },
    { icon: <EmailIcon />, text: primaryEmail, link: `mailto:${primaryEmail}` },
  ];

  return (
    <Box>
      <List className="footer-list">
        {contactDetails.map(
          (detail, index) =>
            detail.text && (
              <ListItem key={index}>
                <Link className="text-white" href={detail.link || undefined}>
                  {detail.icon} {detail.text}
                </Link>
              </ListItem>
            )
        )}
      </List>
    </Box>
  );
}

export default ContactDetails;
