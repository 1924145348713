import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import { Link } from "react-router-dom";
import apiService from "../../services/api"; // Import the API service

function Deepam() {
  const [deepamData, setDeepamData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDeepamData = async () => {
      try {
        const response = await apiService.getDeepamData();
        console.log("API Response:", response.data); // Debugging API response
        if (response.data && response.data.success) {
          setDeepamData(response.data.data); // Update state with API data
        } else {
          console.error("API Error:", response.data.message);
        }
      } catch (error) {
        console.error("Fetch Error:", error);
      } finally {
        setLoading(false); // Stop loading once data is fetched
      }
    };

    fetchDeepamData();
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      {loading ? (
        <Typography>Loading...</Typography>
      ) : deepamData.length === 0 ? (
        <Typography>No Deepam records found.</Typography>
      ) : (
        deepamData.map((card, index) => (
          <Card sx={{ mb: 5 }} key={index}>
            <Link
              to={card.image} // Construct PDF URL
              target={card.new_window ? "_blank" : "_self"} // Dynamically set target
              rel={card.new_window ? "noopener noreferrer" : undefined} // Add rel for new tab
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <CardActionArea>
                <CardContent
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between", // Align items properly
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2, // Gap between the icon and text
                    }}
                  >
                    <PictureAsPdfIcon fontSize="large" />
                    <Typography variant="h5" component="div">
                      {card.title}
                    </Typography>
                  </Box>
                  <SimCardDownloadIcon fontSize="large" />
                </CardContent>
              </CardActionArea>
            </Link>
          </Card>
        ))
      )}
    </Box>
  );
}

export default Deepam;
