import React from "react";
import { Box, Link } from "@mui/material";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import { useAppSettings } from "../../contexts/AppSettingsContext";
const socialIcons = [
  { name: "facebook", Icon: FacebookOutlinedIcon },
  { name: "twitter", Icon: XIcon },
  { name: "instagram", Icon: InstagramIcon },
];
function SocialLinks() {
  const { facebookUrl, twitterUrl, instagramUrl } = useAppSettings() || {}; 
  const links = {
    facebook: facebookUrl,
    twitter: twitterUrl,
    instagram: instagramUrl,
  };
  return (
    <Box className="social-icons">
      {socialIcons.map(({ name, Icon }) => {
        const link = links[name]; 
        return (
          link && (
            <Link href={link} target="_blank" key={name}>
              <Icon />
            </Link>
          )
        );
      })}
    </Box>
  );
}
export default SocialLinks;
