import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";

import SocialHallBooking from "../pages/social_hall_booking/SocialHallBooking.js";

import TempleToursRequest from "../pages/temple_tours_request/TempleToursRequest.js";

import Events from "../pages/event/Event.js";
import EventDetails from "../pages/event/EventDetails";
import DynamicLayout from "../layouts/DynamicLayout.js";



function Router({ theme, toggleTheme }) {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/events" element={<Events />} />

      <Route path="/events/:slug" element={<EventDetails />} />

      <Route path="/temple-tours" element={<TempleToursRequest />} />

      <Route path="/social-hall-booking" element={<SocialHallBooking />} />

      <Route path="/:slug" element={<DynamicLayout />} />
    </Routes>
  );
}

export default Router;
