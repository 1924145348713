import React from 'react';
import parse, { domToReact } from 'html-react-parser';
import Grid from '@mui/material/Grid2';
import { Button, Typography } from '@mui/material';

const htmlConvert = ({ htmlContent }) => {
  if (!htmlContent || typeof htmlContent !== 'string') {
    return null;
  }

  // Function to replace image paths
  const replaceImagePaths = (node) => {
    if (node.name === 'img' && node.attribs?.src) {
      const baseUrl = 'https://htnadmin.cloudhostingcompany.in';
      node.attribs.src = node.attribs.src
        .replace(/^(\.\.\/)+/, `${baseUrl}/`)
        .replace(`${baseUrl}..`, baseUrl);
    }
  };

  // Function to handle mapping grid classes
  const replaceColClasses = (node) => {
    if (node.name === 'div' && node.attribs?.class?.includes('col-')) {
      const classList = node.attribs.class.split(' ');
      const gridSizes = {};
      const gridOrder = {};
      const customClasses = [];

      classList.forEach((cls) => {
        if (cls.startsWith('col-')) {
          const [_, size, value] = cls.split('-');
          if (size === 'md') gridSizes.md = parseInt(value, 10);
          if (size === 'sm') gridSizes.sm = parseInt(value, 10);
          if (size === 'xs') gridSizes.xs = parseInt(value, 10);
        } else if (cls.startsWith('order-')) {
          const [_, size, value] = cls.split('-');
          if (size === 'md') gridOrder.md = parseInt(value, 10);
          if (size === 'sm') gridOrder.sm = parseInt(value, 10);
          if (size === 'xs') gridOrder.xs = parseInt(value, 10);
        } else {
          customClasses.push(cls); // Collect custom classes
        }
      });

      return (
        <Grid
          size={gridSizes}
          order={gridOrder}
          className={customClasses.join(' ')} // Add custom classes here
        >
          {domToReact(node.children, { replace: replaceNodes })}
        </Grid>
      );
    }

    // Replace buttons
    if (node.name === 'a' && node.attribs?.class?.includes('btn')) {
      return (
        <Button
          component="a"
          variant="contained"
          color="primary"
          href={node.attribs.href}
          className={node.attribs.class} // Pass button classes here
        >
          {domToReact(node.children, { replace: replaceNodes })}
        </Button>
      );
    }

    // Replace headings
    if (node.name === 'h2') {
      return (
        <Typography variant="h4" component="h2">
          {domToReact(node.children, { replace: replaceNodes })}
        </Typography>
      );
    }

    // Replace paragraphs
    if (node.name === 'p') {
      return (
        <Typography variant="body1">
          {domToReact(node.children, { replace: replaceNodes })}
        </Typography>
      );
    }
  };

  // Unified replace function
  const replaceNodes = (node) => {
    replaceImagePaths(node); // Ensure images have the correct path

    if (node.name === 'div' && node.attribs?.class) {
      const classList = node.attribs.class.split(' ');
      const alignmentClass = classList.find((cls) =>
        cls.startsWith('align-items-')
      );
      const customClasses = classList.join(' '); // Preserve all classes

      if (node.attribs.class.includes('row')) {
        return (
          <Grid
            container
            spacing={3}
            alignItems={alignmentClass ? alignmentClass : 'center'}
            className={customClasses} // Add custom classes here
          >
            {domToReact(node.children, { replace: replaceNodes })}
          </Grid>
        );
      }

      if (
        node.attribs.class.includes('container-fluid') ||
        node.attribs.class.includes('container')
      ) {
        return (
          <Grid
            container
            spacing={3}
            className={customClasses} // Add custom classes here
          >
            {domToReact(node.children, { replace: replaceNodes })}
          </Grid>
        );
      }
    }

    return replaceColClasses(node); // Handle other cases
  };

  return parse(htmlContent, { replace: replaceNodes });
};

export default htmlConvert;
