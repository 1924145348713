import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Box,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

function NavigationMenu({ navItems, onClose, isMobile }) {
  const [activeSubMenus, setActiveSubMenus] = useState([]);
  const location = useLocation();

  const handleSubMenuClick = (key, event) => {
    event.preventDefault();
    event.stopPropagation();

    setActiveSubMenus((prev) =>
      prev.includes(key)
        ? prev.filter((item) => item !== key) // Remove if already active
        : [...prev, key] // Add if not active
    );
  };

  const handleLinkClick = (event) => {
    if (isMobile) {
      onClose();
    }
    event.stopPropagation();
  };

  return (
    <List className="nav">
      {navItems.map((item, index) => (
        <ListItem key={item.name}>
          <ListItemButton
            component={item.custom_url ? "a" : Link}
            to={
              item.type === "nopage" || item.custom_url ? undefined : item.path
            }
            href={
              item.type === "nopage" ? undefined : item.custom_url || undefined
            }
            target={item.custom_url ? "_blank" : undefined}
            rel={item.custom_url ? "noopener noreferrer" : undefined}
            sx={{
              justifyContent: isMobile ? "space-between" : "flex-start",
              display: "flex",
              alignItems: "center",
            }}
            onClick={item.custom_url ? undefined : onClose}
            className={
              location.pathname === item.path && !item.custom_url
                ? "active"
                : ""
            }
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <ListItemText primary={item.name} />
              {item.subMenu && item.subMenu.length > 0 && (
                <KeyboardArrowDownIcon
                  sx={{ ml: 1 }}
                  onClick={(event) => handleSubMenuClick(index, event)}
                  className={activeSubMenus.includes(index) ? "active" : ""}
                />
              )}
            </Box>
          </ListItemButton>
          {item.subMenu && item.subMenu.length > 0 && (
            <List
              disablePadding
              className={`sub-menu ${
                activeSubMenus.includes(index) ? "active" : ""
              }`}
            >
              {item.subMenu.map((subItem, subIndex) => (
                <ListItem key={subItem.title} disablePadding>
                  <ListItemButton
                    component={subItem.custom_url ? "a" : Link}
                    to={
                      subItem.type === "nopage" || subItem.custom_url
                        ? undefined
                        : `/${subItem.slug}`
                    }
                    href={
                      subItem.type === "nopage"
                        ? undefined
                        : subItem.custom_url || undefined
                    }
                    target={subItem.custom_url ? "_blank" : undefined}
                    rel={subItem.custom_url ? "noopener noreferrer" : undefined}
                    sx={{ pl: isMobile ? 4 : 2 }}
                    onClick={
                      subItem.custom_url
                        ? undefined
                        : (event) => handleLinkClick(event)
                    }
                    className={
                      location.pathname === `/${subItem.slug}` &&
                      !subItem.custom_url
                        ? "active"
                        : ""
                    }
                  >
                    <ListItemText primary={subItem.title} />
                    {subItem.subMenu && subItem.subMenu.length > 0 && (
                      <ArrowForwardIosIcon
                        sx={{ ml: 1 }}
                        onClick={(event) =>
                          handleSubMenuClick(`${index}-${subIndex}`, event)
                        }
                        className={
                          activeSubMenus.includes(`${index}-${subIndex}`)
                            ? "active"
                            : ""
                        }
                      />
                    )}
                  </ListItemButton>
                  {subItem.subMenu && subItem.subMenu.length > 0 && (
                    <List
                      disablePadding
                      className={`sub-sub-menu ${
                        activeSubMenus.includes(`${index}-${subIndex}`)
                          ? "active"
                          : ""
                      }`}
                    >
                      {subItem.subMenu.map((thirdItem) => (
                        <ListItem key={thirdItem.title} disablePadding>
                          <ListItemButton
                            component={thirdItem.custom_url ? "a" : Link}
                            to={
                              thirdItem.type === "nopage" ||
                              thirdItem.custom_url
                                ? undefined
                                : `/${thirdItem.slug}`
                            }
                            href={
                              thirdItem.type === "nopage"
                                ? undefined
                                : thirdItem.custom_url || undefined
                            }
                            target={
                              thirdItem.custom_url ? "_blank" : undefined
                            }
                            rel={
                              thirdItem.custom_url
                                ? "noopener noreferrer"
                                : undefined
                            }
                            sx={{ pl: isMobile ? 6 : 4 }}
                            onClick={
                              thirdItem.custom_url
                                ? undefined
                                : (event) => handleLinkClick(event)
                            }
                            className={
                              location.pathname === `/${thirdItem.slug}` &&
                              !thirdItem.custom_url
                                ? "active"
                                : ""
                            }
                          >
                            <ListItemText primary={thirdItem.title} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  )}
                </ListItem>
              ))}
            </List>
          )}
        </ListItem>
      ))}
    </List>
  );
}

export default NavigationMenu;
