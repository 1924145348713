import React from "react";
import { useParams } from "react-router-dom";
import { eventData } from "../../static-data/event-data.js";
import { Container, Box, Typography } from "@mui/material";
import InnerLayout from "../../layouts/InnerLayout.js";
import InnerBanner from "../../images/inner-banner.jpg";
import Grid from "@mui/material/Grid2";

function EventDetails() {
  const { slug } = useParams(); // Get slug from URL
  const event = eventData.find((item) => item.slug === slug); // Find event by slug

  if (!event) {
    return (
      <Container>
        <Typography variant="h4" sx={{ mt: 5 }}>
          Event not found!
        </Typography>
      </Container>
    );
  }

  return (
    <InnerLayout>
      <Box component="section" className="inner-banner" sx={{ p: 0 }}>
        <Box component="img" src={InnerBanner} />

        <Box className="inner">
          <Container maxWidth="xl">
            <Typography
              component="h1"
              variant="h1"
              className="section-title text-white"
            >
              Events Details
            </Typography>
          </Container>
        </Box>
      </Box>
      <Box component="section">
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item size={{ md: 6 }}>
              <Box
                component="img"
                src={event.image}
                alt={event.title}
                className="w-100"
              />
            </Grid>
            <Grid item size={{ md: 6 }}>
              <Typography
                component="h1"
                variant="h3"
                dangerouslySetInnerHTML={{ __html: event.title }}
              />

              <Typography
                variant="body1"
                dangerouslySetInnerHTML={{ __html: event.description }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </InnerLayout>
  );
}

export default EventDetails;
