import React from "react";
import { Box, Container } from "@mui/material";
import Grid from "@mui/material/Grid2";
import DefaultImage from "../../images/banner-bg.jpg";
import HeroSlider from "../hero_slider/HeroSlider";
import CallForAction from "../call_for_action/CallForActtion";
import "../page_banner/banner.css";
import { useAppSettings } from "../../contexts/AppSettingsContext";

function PageBanner() {
  const { bannerImage } = useAppSettings() || {};

  return (
    <Box
      component="section"
      className="banner"
      sx={{
        backgroundImage: `url(${bannerImage || DefaultImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Container maxWidth="fluid">
        <Grid
          container
          spacing={2}
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item size={{ xs: 12, md: 9 }}>
            <HeroSlider />
          </Grid>
          <Grid item size={{ xs: 12, md: 3 }}>
            <CallForAction />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default PageBanner;
