import React from "react";
import { Box, Card, Container, Link, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useAppSettings } from "../../contexts/AppSettingsContext";

import OmImg from "../../images/om.svg";
import PledgeImg from "../../images/pledge.svg"; // Example image, replace with the actual one

function CallForAction() {
  const { pujaPrayersLink, pledgeSupportLink } = useAppSettings() || {};

  const actionBlocks = [
    {
      imgSrc: OmImg,
      title: "Puja & Prayers Request <br/>Scheduling",
      link: pujaPrayersLink,
    },
    {
      imgSrc: PledgeImg,
      title: "Pledge Your <br/>Support",
      link: pledgeSupportLink,
    },
  ];

  return (
    <Box component="div" className="callforactiondiv">
      <Container maxWidth="xl">
        <Box className=" callforaction">
          <Grid
            container
            spacing={2}
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {actionBlocks.map((block) => (
              <Grid
                item
                size={{ md: 12 }}
                key={block.id}
                sx={{ p: 3, mb: 1 }}
                className="bg-tertiary text-white block"
              >
                <Link
                  href={block.link}
                
                  style={{ textDecoration: "none" }}
                >
                  <Box
                    className="call-for-action-section"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Box
                      className="call-for-action-block"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        component="img"
                        src={block.imgSrc}
                        className="icon-50"
                        alt={`${block.title} Icon`}
                      />

                      <Typography
                        component="h5"
                        variant="h5"
                        color="text.primary"
                        className="text-white"
                        dangerouslySetInnerHTML={{ __html: block.title }}
                      />
                    </Box>
                    <Box component="span" sx={{ marginLeft: 2 }}>
                      <ArrowRightAltIcon />
                    </Box>
                  </Box>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default CallForAction;
