import React, { createContext, useContext, useState, useEffect } from "react";
import apiService from "../services/api";
const AppSettingsContext = createContext();
export const AppSettingsProvider = ({ children }) => {
  const [settings, setSettings] = useState({ logo: "", siteName: "" });
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await apiService.getApplicationSettings();
        if (response.data.success) {
          setSettings({
            logo: response.data.data["logo"] || "",
            siteName: response.data.data["site-name"] || "",
            footerMenu: response.data.data["footer-menu"] || "",
            pujaPrayersLink: response.data.data["puja-prayers-link"] || "",
            pledgeSupportLink: response.data.data["pledge-support-link"] || "",
            whatsappScanImage: response.data.data["whatsapp-scan-image"] || "",
            balviharLink: response.data.data["balvihar-link"] || "",
            deepamLink: response.data.data["deepam-link"] || "",
            templeTimings: response.data.data["temple-timings"] || "",
            copyrightText: response.data.data["copyright-text"] || "",
            bannerImage: response.data.data["banner-image"] || "",
            primaryPhoneNumber:
              response.data.data["primary-phone-number"] || "",
            primaryEmail: response.data.data["primary-email"] || "",
            address: response.data.data["address"] || "",
            facebookUrl: response.data.data["facebook-url"] || "",
            twitterUrl: response.data.data["twitter-url"] || "",
            instagramUrl: response.data.data["instagram-url"] || "",
          });
        }
      } catch (error) {
        console.error("Error fetching application settings:", error);
      }
    };
    fetchSettings();
  }, []);
  return (
    <AppSettingsContext.Provider value={settings}>
      {children}
    </AppSettingsContext.Provider>
  );
};
export const useAppSettings = () => useContext(AppSettingsContext);
