import * as React from "react";
import { CssVarsProvider } from "@mui/joy/styles";
import { Box, Typography, Container } from "@mui/material";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Radio, { radioClasses } from "@mui/joy/Radio";
import RadioGroup from "@mui/joy/RadioGroup";
import Person from "@mui/icons-material/Person";
import People from "@mui/icons-material/People";
import Sheet from "@mui/joy/Sheet";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import FormLabel from "@mui/joy/FormLabel";

export default function TypeOfEvent() {
  // Array of packages with names and prices
  const packages = [
    { name: "Main Hall Package", price: 451 },
    { name: "Mini Hall Package", price: 201 },
    { name: "Community Hall Package", price: 451 },
  ];

  return (
    <Box className="payment-radio-box">
    <CssVarsProvider >
      <Typography
        component="h1"
        variant="h1"
        className="section-title text-primary mb-10"
      >
        Type of Event
      </Typography>
      <RadioGroup
        aria-label="Your plan"
        name="people"
        defaultValue="Individual"
      >
        <List
          className="inline-radio"
          sx={{
            minWidth: 240,
            "--List-gap": "0.5rem",
            "--ListItem-paddingY": "1rem",
            "--ListItem-radius": "8px",
            "--ListItemDecorator-size": "32px",
          }}
        >
          {["Personal", "Community"].map((item, index) => (
            <ListItem variant="outlined" key={item} sx={{ boxShadow: "sm" }}>
              <ListItemDecorator>
                {[<Person />, <People />][index]}
              </ListItemDecorator>
              <Radio
                overlay
                value={item}
                label={item}
                sx={{ flexGrow: 1, flexDirection: "row-reverse" }}
                slotProps={{
                  action: ({ checked }) => ({
                    sx: (theme) => ({
                      ...(checked && {
                        inset: -1,
                        border: "2px solid",
                        borderColor: theme.vars.palette.primary[500],
                      }),
                    }),
                  }),
                }}
              />
            </ListItem>
          ))}
        </List>
      </RadioGroup>

      <Box className="prizes-radio">
        <Typography
          component="h1"
          variant="h1"
          className="section-title  mb-10"
        >
          Packages
        </Typography>

        <RadioGroup
          className="radiogroup"
          aria-label="platform"
          defaultValue="Website"
          overlay
          name="platform"
          sx={{
            flexDirection: "row",
            gap: 2,
            [`& .${radioClasses.checked}`]: {
              [`& .${radioClasses.action}`]: {
                inset: -1,
                border: "3px solid",
                borderColor: "primary.500",
              },
            },
            [`& .${radioClasses.radio}`]: {
              display: "contents",
              "& > svg": {
                zIndex: 2,
                position: "absolute",
                top: "-8px",
                right: "-8px",
                bgcolor: "background.surface",
                borderRadius: "50%",
              },
            },
          }}
        >
          {packages.map((pkg) => (
            <Sheet
              key={pkg.name}
              variant="outlined"
              sx={{
                borderRadius: "md",
                boxShadow: "sm",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 1.5,
                p: 2,
                minWidth: 120,
              }}
            >
              <Radio
                id={pkg.name}
                value={pkg.name}
                checkedIcon={<CheckCircleRoundedIcon />}
              />
              <Typography
                component="h4"
                variant="h4"
                className="section-title text-primary mb-10"
              >
                ( ${pkg.price} )
              </Typography>    

              <FormLabel htmlFor={pkg.name}>{pkg.name}</FormLabel>
            </Sheet>
          ))}
        </RadioGroup>
      </Box>
    </CssVarsProvider>
    </Box>
  );
}
