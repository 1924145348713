import event01 from "../images/event1.jpg";
import event02 from "../images/event2.jpg";
import event03 from "../images/event3.jpg";
import event04 from "../images/event4.jpg";

export const eventData = [
  {
    image: event01,
    title: "Mandalakaala Samaapthi Pooja<br />(Swamy Ayyappa Pooja)",
    slug:"mandalakaala-samaapthi-poojabr-swamy-ayyappa-pooja",
    description:"<h2>Title here</h2> <p>This best the rulle  This best the rulle  This best the rulle  This best the rulle  This best the rulle  This best the rulle  This best the rulle </p>",
    date: "Saturday December 28th,",
    time: "9:00AM - 12:00PM",
    link: "slug",
  },

  {
    image: event02,
    title: "Pradhana Anaghashtami<br />(Dattatreya Anagha Vratam)",
    description:"<h2>Title here</h2> <p>This best the rulle  This best the rulle  This best the rulle  This best the rulle  This best the rulle  This best the rulle  This best the rulle </p>",
    slug:"pradhana-anaghashtamibr-dattatreya-anagha-vratam",
    date: "Monday, December 23rd,",
    time: "6:30PM",
    link: "slug",
  },

  {
    image: event03,
    title: "Monthly Sankashti<br />(Sankatahara Chathurthi Puja)",
    description:"<h2>Title here</h2> <p>This best the rulle  This best the rulle  This best the rulle  This best the rulle  This best the rulle  This best the rulle  This best the rulle </p>",
    slug:"monthly-sankashtibr-sankatahara-chathurthi-puja",
    date: "Wednesday December 18th, 2024,",
    time: "6:00PM - 8:00PM",
    link: "slug",
  },

  {
    image: event04,
    title: "today event",
    description:"<h2>Title here</h2> <p>This best the rulle  This best the rulle  This best the rulle  This best the rulle  This best the rulle  This best the rulle  This best the rulle </p>",
    slug:"today-event",
    date: "monday Dec 16th, 2024,",
    time: "11:00 AM",
    link: "slug",
  },

  {
    image: event04,
    title: "Community Sri SatyaNarayana Swamy Vratam",
    description:"<h2>Title here</h2> <p>This best the rulle  This best the rulle  This best the rulle  This best the rulle  This best the rulle  This best the rulle  This best the rulle </p>",
    slug: "community-sri-satyanarayana-swamy-vratam",
    date: "Saturday Dec 14th, 2024,",
    time: "11:00 AM",
    link: "slug",
  },
];
