import React, { useEffect, useState } from "react";
import { Box, Container, Typography } from "@mui/material";
import apiService from "../../services/api";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export default function ImageGallery() {
  const [galleryData, setGalleryData] = useState([]);
  const [title, setTitle] = useState("");
  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  useEffect(() => {
    // Fetch photo gallery data from the API
    const fetchGalleryData = async () => {
      try {
        const response = await apiService.getPhotoGalleries();
        if (response.data.success) {
          const { title, image_gallery } = response.data.data;
          setTitle(title);
          setGalleryData(JSON.parse(image_gallery)); // Parse the JSON string to an array
        }
      } catch (error) {
        console.error("Error fetching photo gallery data:", error);
      }
    };
    fetchGalleryData();
  }, []);
  return (
    <Box component="section" className="image-gallery">
      <Container maxWidth="xl">
        <Typography
          component="h2"
          variant="h1"
          className="section-title text-primary"
        >
      Temple Dieties photos
        </Typography>
        <Box className="image-gallery">
          <Slider {...settings}>
            {galleryData.map((item, index) => (
              <Box key={index}>
                <Box component="figure" sx={{ m: 0, px: 2 }}>
                  <Box
                    component="img"
                    alt={item.alt_text || "Gallery Image"}
                    src={`${item.path}`}
                    loading="lazy"
                    className="w-100"
                  />
                </Box>
              </Box>
            ))}
          </Slider>
        </Box>
      </Container>
    </Box>
  );
}
