import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { eventData } from "../../static-data/event-data.js";
import EventCard from "../EventCard.js";
import godImage from "../../images/god.jpg";
import { Box, Link } from "@mui/material";
function EventsSlider() {
  // Helper function to parse dates more reliably
  const parseDate = (dateString) => {
    const cleanedDate = dateString
      .replace(
        /(Monday|Tuesday|Wednesday|Thursday|Friday|Saturday|Sunday),?/gi,
        ""
      )
      .replace(/(\d+)(th|rd|nd|st)/, "$1") // Remove ordinal suffixes
      .replace(/,/g, ""); // Remove commas
    const currentYear = new Date().getFullYear();
    const eventDateWithYear = cleanedDate.includes(currentYear)
      ? cleanedDate
      : cleanedDate + ` ${currentYear}`;
    return new Date(eventDateWithYear); // Parse the cleaned date with year
  };
  const getUpcomingEvents = () => {
    const currentDate = new Date();
    return eventData.filter((event) => {
      const eventDate = parseDate(event.date);
      return eventDate >= currentDate.setHours(0, 0, 0, 0);
    });
  };
  const upcomingEvents = getUpcomingEvents();
  // Add static event for the last slide
  const staticEvent = {
    isStatic: true,
    title: "Past Events",
    image: "path/to/your/static-image.jpg", // Replace with the path to your image
    link: "/past-events", // Replace with your past events link
  };
  const eventsWithStaticSlide = [...upcomingEvents, staticEvent];
  const settings = {
    dots: eventsWithStaticSlide.length > 3,
    infinite: eventsWithStaticSlide.length > 3, 
    arrows: eventsWithStaticSlide.length > 3, 
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
           autoplay: true,
           dots: false,
           infinite: eventsWithStaticSlide.length > 1, 
           arrows: eventsWithStaticSlide.length > 1, 
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
           autoplay: true,
           dots: false,
           infinite: eventsWithStaticSlide.length > 1, 
           arrows: eventsWithStaticSlide.length > 1, 
        },
      },
    ],
  };
  
  return (
    <Slider {...settings}>
      {eventsWithStaticSlide.length > 0 ? (
        eventsWithStaticSlide.map((event, index) => (
          <Box key={index}>
            {event.isStatic ? (
              <Box className="static-event-card">
                <Box
                  component="img"
                  src={godImage}
                  alt="Past Events"
                  className="w-100 theme-radius"
                />
                <Link href="#" className="btn btn-primary">
                  View Past Events
                </Link>
              </Box>
            ) : (
              <EventCard event={event} />
            )}
          </Box>
        ))
      ) : (
        <Box>No upcoming events</Box>
      )}
    </Slider>
  );
}
export default EventsSlider;
